<template>
  <div
    class="spinner-container2 d-flex align-items-center justify-content-center"
    :class="{ solid, fixed }"
    data-test="spinner"
  >
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
/** Spinner component for showing
 *  of loading state
 *
 *  @example
 *  <base-spinner
 *   v-if="showSpinner"
 *   solid
 *   fixed
 *  />
 *
 *  @remark
 *  By default opacity of spinner background is 50%
 *  Use property "solid" to hide page content
 *
 *  @remark
 *  Use "fixed" propery to make spinner
 *  position fixed to viewport
 *
 */

export default {
  name: 'BaseSpinner',
  props: {
    solid: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.spinner-container2 {
  top: 0;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--c-white-rgb), 0.5);

  &.solid {
    background: var(--c-white);
  }

  &.fixed {
    position: fixed;
  }
}
</style>
