<template>
  <div v-if="loading" class="position-absolute top-0 bottom-0 start-0 end-0 z-3">
    <base-spinner2 />
  </div>
  <div class="reporting management-page">
    <div class="title">Monthly Billing</div>


    <!-- Bootstrap Tabs -->
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item" role="presentation">
        <button
            class="nav-link active"
            data-bs-toggle="tab"
            data-bs-target="#bill-details"
            type="button"
            role="tab"
            aria-controls="bill-details"
            aria-selected="true"
        >
          Billing
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
            class="nav-link"
            data-bs-toggle="tab"
            data-bs-target="#bill-config"
            type="button"
            role="tab"
            aria-controls="bill-config"
            aria-selected="false"
        >
          Configuration
        </button>
      </li>
    </ul>

    <div class="tab-content">
      <div id="bill-details" class="tab-pane fade show active" role="tabpanel" aria-labelledby="bill-details-tab">
        <ag-grid
            ref="agRef"
            apiurl="/api/monthly-billing"
            :define-defs="columnDefinitionsBillDetails"
            group-panel=""
            :excel-options="{ fileName: 'MonthlyBilling.xlsx' }"
            height="500px"
            :columns="[]"
            :side-bar="true"
        >
        </ag-grid>
      </div>
    </div>

    <div id="billingConfigurationTabContent" class="tab-content">
      <div id="bill-config" class="tab-pane fade" role="tabpanel" aria-labelledby="bill-config-tab">
        <ag-grid
            ref="agRefConfig"
            apiurl="/api/monthly-billing/merchants"
            :define-defs="columnDefinitionsForMonthlyConfiguration"
            group-panel=""
            :excel-options="{ fileName: 'MonthlyBillingConfiguration.xlsx' }"
            height="500px"
            :columns="[]"
            :side-bar="true"
        >
        </ag-grid>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import AgGrid from '@/components/AgGrid.vue'; // Assuming this is your AgGrid component
import { axiosInstance } from '@/services/http';
import BaseSpinner2 from '@/components/base/BaseSpinner2.vue';
import { DateTime } from 'luxon';

// Data variables
const loading = ref(false);
const agRef = ref();
const agRefConfig = ref();

// Define the column definitions for Bill Details table
/**
 * @typedef {Object} MonthlyBillingReportEntry
 * @property {string} id
 * @property {string} name
 * @property {string} parent_name
 * @property {string|null} monthly_fee
 * @property {string|null} monthly_minimum
 * @property {string|null} parent_monthly_fee
 * @property {string|null} parent_monthly_minimum
 * @property {string} month - Format: YYYY-MM
 * @property {number} transaction_count
 * @property {string|null} total_transaction_amount
 * @property {string|null} last_transaction_date_in_month - Timestamp with timezone, e.g. '2024-09-12T03:28:03.126631+00:00'
 * @property {boolean} is_transacting_in_month
 * @property {boolean} is_month_paid
 * @property {boolean} is_monthly_billing_enabled
 */

/**
 * @type {import("ag-grid-community").ColDef<MonthlyBillingReportEntry, *>[]}
 */
const columnDefinitionsBillDetails = [
  { headerName: 'Merchant', field: 'name' },
  { headerName: 'Transaction Count', field: 'transaction_count' },
  { headerName: 'Monthly Minimum', field: 'monthly_minimum', valueFormatter: params => parseFloat(params.value).toFixed(2) },
  { headerName: 'Monthly Fee', field: 'monthly_fee' },
  { headerName: 'Month', field: 'month', sort: 'desc' },
  { headerName: 'Is Transacting In Month', field: 'is_transacting_in_month' },
  { headerName: 'Total Transaction Amount', field: 'total_transaction_amount' },
  { headerName: 'Last Transaction Date In Month', field: 'last_transaction_date_in_month', valueFormatter: params => !params.value ? '' : DateTime.fromISO(params.value).toFormat('ff') },
  { headerName: 'Bill is Paid', field: 'is_month_paid' },
  {
    headerName: 'Manually Process Monthly Bill',
    colId: 'manuallyProcess',
    sortable: false,
    /**
     * @param {import("ag-grid-community").ICellRendererParams<MonthlyBillingReportEntry, *, *>} params
     */
    cellRenderer: function(params) {
      if (params.data.is_month_paid) {
        return '<p>This bill is already paid</p>'
      }

      if (!params.data.is_monthly_billing_enabled) {
        return '<p>This merchant is not enabled</p>'
      }

      /**
       * @type {HTMLButtonElement}
       */
      let button = document.createElement('a')
      button.innerText = 'Pay this bill';
      button.role = 'button';
      button.classList.add('btn')
      button.classList.add('btn-sm')
      button.classList.add('btn-link')
      button.addEventListener('click', async () => {
        button.classList.add('disabled');
        try {
          await axiosInstance(`/api/monthly-billing/merchants/${params.data.id}/merchant-bills`, {
            method: "POST",
            data: {
              month: params.data.month,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          });
        } catch (e) {
          console.error('columnDefinitionsBillDetails cellRenderer manuallyProcess', e);
        } finally {
          button.classList.remove('disabled');
          agRef?.value?.getAPI().then();
        }
      });

      return button;
    }
  }
];

/**
 * @type {import("ag-grid-community").ColDef[]}
 */
const columnDefinitionsForMonthlyConfiguration = [
  { headerName: 'Merchant', field: 'name' },
  { headerName: 'Parent Name', field: 'parent.name' },
  {
    headerName: 'Monthly Billing Enabled',
    field: 'is_monthly_billing_enabled',
    // https://www.ag-grid.com/javascript-data-grid/provided-cell-editors-checkbox/
    cellRenderer: 'agCheckboxCellRenderer',
    cellEditor: 'agCheckboxCellEditor',
    onCellValueChanged: async (event) => {
      console.log('onCellValueChanged', event);
      loading.value = true;

      let newValue = event.data.is_monthly_billing_enabled;
      try {
        let response = await axiosInstance(`/api/monthly-billing/merchants/${event.data.id}`, {
          // if new value is true, PUT it, otherwise DELETE it
          method: newValue ? 'PUT' : 'DELETE',
        });
        void response.data;
      } catch (e) {
        console.error('onCellValueChanged is_monthly_billing_enabled', e);
        // set it back
        event.data.is_monthly_billing_enabled = !newValue;
        event.api.refreshCells({ rowNodes: [event.node], force: true });
      } finally {
        loading.value = false;
      }
    },
    editable: true,
  },
]

</script>

<style lang="scss" scoped>
/* General page layout */
.management-page {
  padding: 20px;
  background-color: #f8f9fa; /* Lighter background for a cleaner look */
}

.title {
  color: #343a40;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Tabs */
.nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

.nav-item .nav-link {
  color: #495057;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 0 !important;
}

.nav-item .nav-link.active {
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 600;
  color: #007bff;
}

/* Table Container */
.table {
  margin-top: 20px;
  height: 60vh; /* Reduce height to give more space */
  border: 1px solid #dee2e6;
  border-radius: 6px;
  background: #fff;
}

/* Totals Text */
.refresh-text {
  font-size: 18px;
  color: #495057;
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;
}

/* Buttons */
.subs-btn {
  height: 40px;
  background: #007bff !important;
  border-color: #007bff !important;
  color: #fff !important;
  border-radius: 4px;
  transition: background 0.3s;

  &:hover {
    background: #0056b3 !important;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .management-page {
    padding: 10px;
  }

  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .nav-item .nav-link {
    font-size: 14px;
  }

  .table {
    height: 50vh;
  }
}

/* Custom styles for ag-grid */
.ag-theme-alpine {
  --ag-header-background-color: #f1f3f5;
  --ag-row-hover-color: #f8f9fa;
  --ag-header-cell-text-color: #343a40;
  --ag-odd-row-background-color: #fff;
  --ag-header-font-weight: 600;
}

</style>




<!--<template>-->
<!--  <div class="clients management-page">-->
<!--    <div class="mt-1 position-relative">-->
<!--      <div class="title" style="color: #383838; font-size: 24px;">Transactions 3x Over Standard Deviation</div>-->
<!--      <div class="risk-table">-->
<!--        <BaseSpinner v-if="monitoredTransactionsLoading" />-->
<!--        <table v-else class="table">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>Merchant</th>-->
<!--            <th>Transaction</th>-->
<!--            <th>Amount</th>-->
<!--            <th>Created At</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr v-for="t in monitoredTransactions" :key="t.transaction_id">-->
<!--            <td>{{ t.merchant }}</td>-->
<!--            <td>{{ t.transaction_id }}</td>-->
<!--            <td>{{ t.amount }}</td>-->
<!--            <td>{{ formatDate(t.created_at) }}</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script setup>-->
<!--import { ref, onMounted } from 'vue';-->
<!--import AgGrid from '@/components/AgGrid.vue'-->
<!--import BaseSpinner from "@/components/base/BaseSpinner.vue";-->
<!--import {router} from "@/router";-->
<!--import CardTableItem from "@/components/cards/CardTableItem.vue";-->
<!--import useService from "@/composables/common/services";-->
<!--const { apiService } = useService();-->
<!--import http from "@/services/http";-->

<!--const { DateTime } = require('luxon');-->

<!--const monitoredTransactionsLoading = ref(false);-->
<!--const monitoredTransactions = ref([]);-->

<!--// Date formatting function-->
<!--function formatDate(dateString) {-->
<!--  if (!dateString) return '';-->
<!--  const date = DateTime.fromISO(dateString);-->
<!--  return date.toFormat('yyyy-MM-dd HH:mm:ss');-->
<!--}-->

<!--async function fetchMonitoredTransactions() {-->
<!--  monitoredTransactionsLoading.value = true;-->
<!--  try {-->
<!--    const response = await apiService.get(`api/transaction-monitoring`);-->
<!--    monitoredTransactions.value = [...response.message.standard_deviation_list];-->
<!--  } catch (error) {-->
<!--    console.error('Error fetching risk information:', error);-->
<!--  } finally {-->
<!--    monitoredTransactionsLoading.value = false;-->
<!--  }-->
<!--}-->


<!--onMounted(() => {-->
<!--  fetchMonitoredTransactions();-->
<!--});-->

<!--const apiUrl = ref(`/api/transaction-monitoring`)-->
<!--const columnDefs = [-->
<!--    {-->
<!--      headerName: 'Merchant',-->
<!--      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.merchant;},-->
<!--    },-->
<!--    {-->
<!--      headerName: 'Transaction',-->
<!--      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.transaction_id;},-->
<!--    },-->
<!--    {-->
<!--      headerName: 'Amount',-->
<!--      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.amount;},-->
<!--    },-->
<!--    {-->
<!--      headerName: 'Created At',-->
<!--      valueGetter: (p) => { if(!p.data) return ''; return p.data.message.standard_deviation_list.created_at;},-->
<!--      sort:'desc',-->
<!--      valueFormatter: (params) => {-->
<!--        if (!params.value) {-->
<!--          return '';-->
<!--        }-->

<!--        const date = new Date(params.value);-->

<!--        const v = DateTime.fromJSDate(date);-->

<!--        return v.toFormat('yyyy-MM-dd hh:mm:ss');-->
<!--      }-->
<!--    }-->
<!--  ]-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--.loader {-->
<!--  z-index: 2;-->
<!--}-->
<!--</style>-->

<!--<style lang="scss">-->
<!--.clients {-->
<!--  .actions {-->
<!--    height: 100% !important;-->
<!--  }-->

<!--  .action-btn {-->
<!--    line-height: initial;-->
<!--    width: 30px !important;-->
<!--    height: 30px !important;-->
<!--    padding: 2px 1px !important;-->
<!--    font-size: 14px !important;-->
<!--    border-radius: 50% !important;-->

<!--    .icon-container {-->
<!--      width: auto !important;-->

<!--      svg {-->
<!--        width: 19px !important;-->
<!--      }-->
<!--    }-->

<!--    &.success {-->
<!--      color: #fff !important;-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->
