<template>
  <div class="reporting management-page">
    <div class="title">Risk Information</div>

    <!-- Bootstrap Tabs -->
    <ul class="nav nav-tabs" id="billingTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="bill-details-tab" data-bs-toggle="tab" href="#bill-details" role="tab" aria-controls="bill-details" aria-selected="true">3x Std Dev</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="rolling-billing-tab" data-bs-toggle="tab" href="#rolling-billing" role="tab" aria-controls="rolling-billing" aria-selected="false" @click="rollingBillingHack">Over High Ticket</a>
      </li>
      <!-- NEW TAB FOR HIGH VELOCITY -->
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="velocity-tab" data-bs-toggle="tab" href="#velocity-tab-pane"
           role="tab" aria-controls="velocity-tab-pane" aria-selected="false" @click="velocityHack">
          High Velocity
        </a>
      </li>
    </ul>

    <div class="tab-content" id="billingTabContent">
      <div class="tab-pane fade show active" id="bill-details" role="tabpanel" aria-labelledby="bill-details-tab">
        <ag-grid
            ref="agRef"
            :define-defs="lineItemColDefs"
            group-panel=""
            :excel-options="{ fileName: 'StandardDeviations.xlsx' }"
            height="500px"
            :columns="[]"
            :side-bar="true"
        >
        </ag-grid>
      </div>

      <div class="tab-pane fade" id="rolling-billing" role="tabpanel" aria-labelledby="rolling-billing-tab">
        <ag-grid
            ref="agRefRolling"
            :apiurl="() => []"
            :define-defs="rollingColDefs"
            group-panel=""
            :excel-options="{ fileName: 'OverHighTicketLimit.xlsx' }"
            height="500px"
            :columns="[]"
            :side-bar="true"
            @first-data-rendered="onFirstDataRendered"
        >
        </ag-grid>
      </div>

      <!-- NEW TAB PANE FOR HIGH VELOCITY -->
      <div class="tab-pane fade" id="velocity-tab-pane" role="tabpanel" aria-labelledby="velocity-tab">
        <ag-grid
            ref="agRefVelocity"
            :apiurl="() => []"
            :define-defs="velocityColDefs"
            group-panel=""
            :excel-options="{ fileName: 'HighVelocity.xlsx' }"
            height="500px"
            :columns="[]"
            :side-bar="true"
            @first-data-rendered="onVelocityDataRendered"
        >
        </ag-grid>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import AgGrid from '@/components/AgGrid.vue'; // or wherever your AgGrid is located
import http from '@/services/http';

// --------------------------------------------------
// Refs for the existing tabs
// --------------------------------------------------
const agRef = ref();          // 3x Std Dev
const agRefRolling = ref();   // Over High Ticket

// --------------------------------------------------
// Refs for the NEW High Velocity tab
// --------------------------------------------------
const agRefVelocity = ref();        // Reference to the grid for High Velocity
const velocityData = ref([]);       // Data for the High Velocity grid

// Existing refs for the other two tables
const billDetailsData = ref([]);
const rollingBillingData = ref([]);

// Watchers to update row data on changes
watch([rollingBillingData, agRefRolling], () => {
  if (!rollingBillingData.value) return;
  agRefRolling.value?.setTheRowData(rollingBillingData.value);
});

// WATCH FOR THE NEW VELOCITY DATA
watch([velocityData, agRefVelocity], () => {
  if (!velocityData.value) return;
  agRefVelocity.value?.setTheRowData(velocityData.value);
});

// On mounted, fetch data and populate tables
onMounted(async () => {
  try {
    const response = await http.get(`/api/transaction-monitoring`);
    const message = response.data.message;

    // Table #1: 3x Std Dev
    billDetailsData.value = message.standard_deviation_list;
    agRef?.value?.setTheRowData(billDetailsData.value);
    agRef?.value?.setTheColumnDefs(columnDefinitionsBillDetails);

    // Table #2: Over High Ticket
    rollingBillingData.value = message.transactions_over_high_ticket_limit;
    agRefRolling?.value?.setTheRowData(rollingBillingData.value);
    agRefRolling?.value?.setTheColumnDefs(columnDefinitionsRollingBilling);

    // TABLE #3: HIGH VELOCITY (Your new data source)
    velocityData.value = message.transactions_high_velocity;
    agRefVelocity?.value?.setTheRowData(velocityData.value);
    agRefVelocity?.value?.setTheColumnDefs(columnDefinitionsVelocity);
  } catch (error) {
    console.error('Failed to fetch data', error);
  }
});

// --------------------------------------------------
// Column definitions for table #1: 3x Std Dev
// --------------------------------------------------
const columnDefinitionsBillDetails = [
  { headerName: 'Merchant', field: 'merchant' },
  { headerName: 'Transaction Id', field: 'transaction_id' },
  {
    headerName: 'Amount',
    field: 'amount',
    valueFormatter: params => parseFloat(params.value).toFixed(2),
  },
  { headerName: 'Created At', field: 'created_at', sort: 'desc' },
];

const lineItemColDefs = computed(() => {
  return columnDefinitionsBillDetails;
});

// --------------------------------------------------
// Column definitions for table #2: Over High Ticket
// --------------------------------------------------
const columnDefinitionsRollingBilling = [
  { headerName: 'Merchant', field: 'name' },
  { headerName: 'Transaction Id', field: 'id' },
  { headerName: 'Processing Date', field: 'created_at' },
  {
    headerName: 'High Ticket Limit',
    field: 'limits_high_ticket',
    valueFormatter: params => parseFloat(params.value).toFixed(2),
  },
  {
    headerName: 'Amount',
    field: 'amount',
    valueFormatter: params => parseFloat(params.value).toFixed(2),
  }
];

const rollingColDefs = computed(() => {
  return columnDefinitionsRollingBilling;
});

// --------------------------------------------------
// NEW: Column definitions for table #3: High Velocity
// --------------------------------------------------
const columnDefinitionsVelocity = [
  { headerName: 'Merchant', field: 'name' },
  { headerName: 'Transaction Id', field: 'id' },
  {
    headerName: 'Amount',
    field: 'amount',
    valueFormatter: params => parseFloat(params.value).toFixed(2),
  },
  { headerName: 'Created At', field: 'created_at' },
  { headerName: 'Velocity Risk', field: 'risk_velocity' },
  {
    headerName: 'Hourly Velocity Limit',
    field: 'limits_hourly_velocity',
    valueFormatter: params => params.value ?? '',
  },
];

const velocityColDefs = computed(() => {
  return columnDefinitionsVelocity;
});

// Grid ready function for Over High Ticket
const onFirstDataRendered = (params) => {
  console.log('onFirstDataRendered');
  params.api.sizeColumnsToFit();
  params.api.refreshCells({ force: true });
};

// OPTIONAL: Grid ready function for High Velocity
const onVelocityDataRendered = (params) => {
  console.log('onVelocityDataRendered');
  params.api.sizeColumnsToFit();
  params.api.refreshCells({ force: true });
}

// For Over High Ticket tab
let rollingBillingHackRan = false;
function rollingBillingHack() {
  if (rollingBillingHackRan) return;
  setTimeout(() => agRefRolling.value.resetState(), 100);
  setTimeout(() => agRef.value.resetState(), 100);
  rollingBillingHackRan = true;
}

// For High Velocity tab (optional)
let velocityHackRan = false;
function velocityHack() {
  if (velocityHackRan) return;
  setTimeout(() => agRefVelocity.value.resetState(), 100);
  setTimeout(() => agRef.value.resetState(), 100);
  velocityHackRan = true;
}
</script>

<style lang="scss" scoped>
.management-page {
  padding: 20px;
  background-color: #f8f9fa;

  .title {
    color: #343a40;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .nav-tabs {
    border-bottom: 2px solid #dee2e6;
  }

  .nav-item .nav-link {
    color: #495057;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 0 !important;
  }

  .nav-item .nav-link.active {
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    font-weight: 600;
    color: #007bff;
  }

  .table {
    margin-top: 20px;
    height: 60vh;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    background: #fff;
  }

  .refresh-text {
    font-size: 18px;
    color: #495057;
    font-weight: 600;
    margin-top: 10px;
    display: inline-block;
  }

  .subs-btn {
    height: 40px;
    background: #007bff !important;
    border-color: #007bff !important;
    color: #fff !important;
    border-radius: 4px;
    transition: background 0.3s;

    &:hover {
      background: #0056b3 !important;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;

    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .nav-item .nav-link {
      font-size: 14px;
    }

    .table {
      height: 50vh;
    }
  }
}

/* ag-grid theme overrides */
.ag-theme-alpine {
  --ag-header-background-color: #f1f3f5;
  --ag-row-hover-color: #f8f9fa;
  --ag-header-cell-text-color: #343a40;
  --ag-odd-row-background-color: #fff;
  --ag-header-font-weight: 600;
}
</style>