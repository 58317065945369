<template>
  <div id="app">
    <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import useMobileDimension from '@/composables/common/mobile';

export default {
  props: {
    monthlyVolume: {
      type: Array,
      required: true
    }
  },
  setup() {
    const { isMobile } = useMobileDimension();
    return { isMobile };
  },
  data() {
    return {
      // Define chart options
      chartOptions: {
        chart: {
          id: 'monthly-volume-bar-chart',
          height: 'auto' // default height for larger screens
        },
        yaxis: [
          // {
          //   opposite: false,
          //   title: {
          //     text: 'Transactions'
          //   },
          //   labels: {
          //     formatter: function (value) {
          //       return value.toLocaleString(undefined, {});
          //     }
          //   }
          //   // min: 0,
          //   // max: 0
          // },
          {
            title: {
              text: 'Total'
            },
            labels: {
              formatter: function (value) {
                return '$' + value.toLocaleString(undefined, {});
              }
            },
            opposite: true
            // min: 0,
            // max: 0
          },

        ],
        xaxis: {
          categories: [] // to be filled with month names
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: 'Monthly Payout Volume',
          align: 'center'
        },
        tooltip: {
          y: {
            formatter: function (value, { seriesIndex }) {
              if (seriesIndex === 1 || seriesIndex === 2) {
                return (
                  '$' +
                  value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                );
              }
              return value;
            }
          }
        },
        responsive: [
          {
            breakpoint: 600, // breakpoint where the changes will apply
            options: {
              chart: {
                height: 350 // minimum height for smaller screens
              },
              yaxis: {
                labels: {
                  show: false // hide Y-axis labels
                }
              }
            }
          }
        ]
      },
      // Define the series data for the chart
      series: [
        // {
        //   name: 'Transactions',
        //   yaxis: 'Transactions',
        //
        //   data: [] // to be filled with transactions data
        // },
        {
          name: 'Total',
          yaxis: 'Total',
          data: [] // to be filled with total data
        },
      ]
    };
  },
  watch: {
    monthlyVolume: {
      handler(chartData) {
        this.updateChartData(chartData);
      },
      deep: true
    }
  },
  created() {
    this.updateChartData(this.monthlyVolume);
  },
  methods: {
    updateChartData(chartData) {
      // Uncomment minmax values and below arrays
      // to manually adjust scales for each axes

      // const transactionsValues = chartData.map((item) => item.transactions);
      // const totalValues = chartData.map((item) => parseFloat(item.total));
      // const feeValues = chartData.map((item) => parseFloat(item.fee));
      // const minMax = {
      //   transactions: {
      //     min: Math.min(...transactionsValues),
      //     max: Math.max(...transactionsValues)
      //   },
      //   total: {
      //     min: Math.min(...totalValues),
      //     max: Math.max(...totalValues)
      //   },
      //   fees: {
      //     min: Math.min(...feeValues),
      //     max: Math.max(...feeValues)
      //   }
      // };

      // Sort data by the date to ensure numeric/chronological order
      chartData = chartData.sort((a, b) => new Date(a.month) - new Date(b.month));

      // Transform "2024-09-01T00:00:00" into "2024-09"
      const categories = chartData.map((item) => {
        const dateObj = new Date(item.month);
        const year = dateObj.getUTCFullYear();
        // getUTCMonth() returns 0 for January, so add 1 and pad with 0 if needed
        const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
      });

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories
        }
      };
      // this.series[0].data = chartData.map((item) => item.transactions);
      this.series[0].data = chartData.map((item) => parseFloat(item.total).toFixed(2));
    }
  }
};
</script>
