import axios from 'axios';
import { useStore, store } from 'vuex';
import cookieService from '@/services/cookies';

let instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 100000,
  headers: {
    Authorization: store?.state?.token || ''
  }
});

instance.interceptors.request.use(
  function (config) {
    if (
      config.data &&
      Object.keys(config.data).length &&
      // allows explicitly overriding default to
      // form-encoding by caller
      config.headers['Content-Type'] !== 'application/json'
    ) {
      config.data = new URLSearchParams(config.data);
    }

    let token;

    try {
      token = cookieService.getIdentityToken();
    } catch (err) {
      console.error("error from the cookieService on the interceptor:", err);
    }

    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    console.error("Error with getting token: ", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log("interceptor response: ", response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.error("interceptor response error: ", error);
    return Promise.reject(error);
  }
);

export default instance;

// JFC
export const axiosInstance = instance;
