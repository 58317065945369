import useService from '@/composables/common/services';
import { downloadFile } from '@/helpers';
import { ref } from 'vue';
// import { debounce } from '@/helpers';

export default function () {
  const { apiService } = useService();
  const documents = ref([]);
  // const search = ref(null);

  const getTags = async () => {
    return [];
    return apiService.get(`api/documents/tags`).then((res) => {
      return res.message.tags
        .filter((el) => el)
        .map((el) => {
          const text = el[0].toUpperCase() + el.slice(1, el.length);

          return {
            text,
            value: el
          };
        });
    });
  };

  const getDocuments = (clientid) => {
    let queryString = '';

    if (clientid) {
      queryString += `&clientid=${clientid}&`;
    }

    return apiService
      .get(`api/documents${queryString}`)
      .then((res) => {
        documents.value = res.message.map((el) => {
          if (el[1] && typeof el[1] === 'object') {
            return {
              ...el[1],
              id: el[0]
            };
          }

          return {
            ...el[3],
            id: el[2]
          };
        });
      })
      .catch(() => {
        documents.value = [];
      });
  };

  const downloadDocument = (id) => {
    console.log(id.id);
    console.log("Downloading document with id above");
    return apiService
      .get(`api/documents?id=${id.id}`)
      .then((res) => {
        const data = res.message;
        console.log(res);
        console.log("Response above from downloading document");
        downloadFile({
          file: data.file,
          name: data.filename
        });
      })
      .catch(() => {
        console.error("Error while downloading document")
      });
  };

  const searchDocuments = (clientid) => {
    let queryString = '';

    if (clientid) {
      queryString += `&clientid=${clientid}&`;
    }

    return apiService
      .get(`api/document/search${queryString}`)
      .then((res) => {
        documents.value = res.message.map((el) => {
          if (clientid) {
            return {
              ...el[1],
              id: el[0]
            };
          }

          return {
            ...el[3],
            id: el[2]
          };
        });
      })
      .catch(() => {
        documents.value = [];
      });
  };

  // const handleSearch = debounce((...args) => {
  //   searchDocuments(...args);
  // }, 300);

  return {
    documents,
    // search,

    getTags,
    getDocuments,
    downloadDocument
    // handleSearch
  };
}
