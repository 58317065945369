<script setup>
import {ref, onMounted, computed} from 'vue';
import http from '@/services/http';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
import { useRoute } from 'vue-router'; // Import useRoute
import { PAYMENT_OPTIONS, VIRTUAL_TERMINAL_DEFAULTS } from '@/config/constants';
import { DateTime } from 'luxon';
import { router } from '@/router';
import axios from 'axios';
import { paymentMethodAvailable } from '@/helpers/index';
import BaseCheckbox from "@/components/base/BaseCheckbox.vue";
import BaseTooltip from "@/components/base/BaseTooltip.vue";
import HelpCircleOutline from 'vue-material-design-icons/HelpCircleOutline.vue';
import AskOption from "@/components/links/AskOption.vue";
import { useToast } from 'vue-toastification';

const toast = useToast();
const route = useRoute();

const fields = ref([]);
const jsonOutput = ref('');
const apikey = route.params.apikey;
const isLocalhostOrTest = ref(false);
const linkConfigLoadSuccess = ref(false);

// Inside <script setup>

const paymentOptions = ref([]);
const availablePaymentOptions = ref({
  crypto: false,
  credit_card: false,
  bank: false,
  swipe: false,
  cash: false,
  apple_pay: true, // This is just so they render, both apple and google pay
  google_pay: true,
});
const loadingAvailablePaymentOptions = ref(false);

/**
 * In this component and for the related internal virtual
 * terminal, this controls a cluster of fields that include
 * first/last name, email
 * @type {Ref<UnwrapRef<boolean>>}
 */
const amount = ref(0);
const showBillingDetails = ref(false);
const freeFormAmount = ref(true);
const buttonTopPosition = ref(false);
const showLocationsDropdown = ref(true);
const hasSubscriptionAccess = ref(true);
const showLogoUrl = ref(false);

const changePaymentOptions = (v, disabled) => {
  if (disabled) {
    return;
  }

  const index = paymentOptions.value.indexOf(v);

  if (index > -1) {
    paymentOptions.value.splice(index, 1);
    return;
  }

  paymentOptions.value.push(v);
};

const disableOption = (optionName, disabled) => {
  if (disabled) {
    return;
  }

  if (!paymentOptions.value.includes(optionName)) {
    return;
  }

  changePaymentOptions(optionName);
};

const enableOption = (optionName, disabled) => {
  if (disabled) {
    return;
  }

  if (paymentOptions.value.includes(optionName)) {
    return;
  }

  changePaymentOptions(optionName);
};

const filteredPaymentOptions = computed(() => {
  return PAYMENT_OPTIONS.filter((option) => {
    return !!availablePaymentOptions.value[option.value];
  });
});

const copyIframeLinkToClipboard = async () => {
  if (iframeLink.value) {
    try {
      await navigator.clipboard.writeText(iframeLink.value);
      toast.success("iFrame link copied to clipboard!");
    } catch (err) {
      console.error(err);
      console.error('Failed to copy text');
    }
  } else {
    toast.error('User does not have VIRTUAL_TERMINAL permission.');
  }
};

const copyShortIframeLinkToClipboard = async () => {
  let value = clientLinkConfig.value?.url_id;
  if (value) {
    try {
      await navigator.clipboard.writeText(`https://tripleplaypay.com/url/${value}`);
      toast.success("iFrame link copied to clipboard!");
    } catch (err) {
      console.error(err);
      console.error('Failed to copy text');
    }
  } else {
    toast.error('User does not have VIRTUAL_TERMINAL permission.');
  }
};

/**
 * @typedef {{
 *   id: string
 *   updated_at: string
 *   created_at: string
 *   is_deleted: boolean
 *   api_key: string
 *   callback_url?: string
 *   callback_url_auth_header?: string
 *   merchant: {
 *     id: string
 *     name: string
 *     configuration: {
 *       id: string
 *       customizations: Record<string, *>
 *     }
 *   }
 *   merchant_id: string
 *   name: string
 *   public_key: string
 *   test_api_key: string
 * }} ConfigureTerminalLinkConfig
 */

/**
 * @type {import("vue").Ref<ConfigureTerminalLinkConfig | null>}
 */
const clientLinkConfig = ref(null);

// Assuming getClientDetails is already present or add this method
const getClientDetails = () => {
  loadingAvailablePaymentOptions.value = true;
  return axios
      .get(`/api/client/link-config`, {
        headers: {
          Authorization: `Bearer ${apikey}`
        },
        onlyLoggedIn: false
      })
      .then((res) => {
        loadingAvailablePaymentOptions.value = false;
        clientLinkConfig.value = res.data.message
        const configuration = res?.data.message?.merchant?.configuration || res;

        availablePaymentOptions.value = {
          credit_card: paymentMethodAvailable(configuration.payment),
          bank: paymentMethodAvailable(configuration.ach),
          terminal: false,
          crypto: paymentMethodAvailable(configuration.crypto),
          swipe: true,
          cash: true,
          apple_pay: true,
          google_pay: true
        };

        if (!availablePaymentOptions.value.crypto) {
          disableOption('crypto');
        }

        if (!availablePaymentOptions.value.credit_card) {
          disableOption('credit_card');
        }

        if (!availablePaymentOptions.value.bank) {
          disableOption('bank');
        }

        linkConfigLoadSuccess.value = true;
        return res.message;
      })
      .catch(() => {
        loadingAvailablePaymentOptions.value = false;
      });
};

onMounted(() => {
  isLocalhostOrTest.value =
    window.location.origin.includes('localhost') || apikey === 'test';
});

function moveFieldUp(index) {
  if (index > 0) {
    const temp = fields.value[index];
    fields.value.splice(index, 1);
    fields.value.splice(index - 1, 0, temp);
  }
}

function moveFieldDown(index) {
  if (index < fields.value.length - 1) {
    const temp = fields.value[index];
    fields.value.splice(index, 1);
    fields.value.splice(index + 1, 0, temp);
  }
}

function addField() {
  fields.value.push({
    name: '',
    type: 'text',
    values: [],
    newValue: '',
    valid: true,
    message: '',
    valuesOpen: true,
    required: false
  });
}

function addValueToField(fieldName, value) {
  if (!value) {
    console.error(`Value cannot be blank for field ${fieldName}`);
    return;
  }

  const field = fields.value.find((field) => field.name === fieldName);
  if (field) {
    if (field.values) {
      field.values.push(value);
    } else {
      field.values = [value];
    }
  } else {
    console.error(`Field with name ${fieldName} not found`);
  }
  validateFields();
}

function removeValueFromField(fieldName, valueIndex) {
  const field = fields.value.find((field) => field.name === fieldName);
  if (field && field.values) {
    const valuesArray = field.values;
    if (valueIndex >= 0 && valueIndex < valuesArray.length) {
      valuesArray.splice(valueIndex, 1);
      field.values = valuesArray;
    } else {
      console.error(`Value index ${valueIndex} out of bounds`);
    }
  } else {
    console.error(`Field with name ${fieldName} not found or has no values`);
  }
}

function removeField(index) {
  fields.value.splice(index, 1);
}

function createParamsUrl(customFields) {
  console.log('Saving URL...');
  const params = { ...VIRTUAL_TERMINAL_DEFAULTS };
  params.start = DateTime.now().toFormat('yyyy-MM-dd');
  params.customFields = customFields;
  params.apikey = apikey;
  params.paymentOptions = paymentOptions.value;

  // remove the fullName options as they
  // are duplicative of billing details fields
  params.fullName = false;
  params.showFullName = false;

  // Strange, but this is how email
  // display is controlled on the iFrame
  params.emailReadOnly = true

  if (freeFormAmount.value) {
    // ensure the end user can set the price
    params.amount = 0;
  } else {
    params.amount = amount.value;
  }
  params.freeFormAmount = freeFormAmount.value;
  params.buttonTopPosition = buttonTopPosition.value;
  params.showBillingDetails = showBillingDetails.value;
  params.hasSubscriptionAccess = hasSubscriptionAccess.value;
  params.showLocationsDropdown = showLocationsDropdown.value;
  params.showLogoUrl = showLogoUrl.value;
  const paramString = btoa(JSON.stringify(params));
  const url = `${window.location.origin}/responsive-iframe/payment?params=${paramString}`;
  iframeLink.value = url;
  return url;
}

function generateJson() {
  console.log('Generating JSON...');
  if (!validateFields()) {
    return;
  }
  const output = fields.value.reduce((acc, field) => {
    acc[field.name] = {
      type: field.type,
      ...(field.type !== 'text' &&
        field.type !== 'date' &&
        field.values && { values: field.values }),
      id: field.id
    };
    return acc;
  }, {});
  jsonOutput.value = JSON.stringify(output, null, 2);
  console.debug('Generated JSON:', output);
  return output;
}

function generateFields() {
  console.log('Generating JSON...');
  if (!validateFields()) {
    return;
  }
  const output = fields.value.map((field) => {
    console.log('Generating Field:', field);
    return {
      name: field.name,
      type: field.type,
      // we validate this elsewhere
      // required: field.required,
      required: false,
      id: field.id,
      ...(field.type !== 'text' &&
        field.type !== 'date' &&
        field.values && { values: field.values })
    };
  });
  jsonOutput.value = JSON.stringify(output, null, 2);
  console.debug('Generated JSON:', output);
  return output;
}

function jsonToFields(jsonObject) {
  const fieldsArray = [];
  for (const [key, value] of Object.entries(jsonObject)) {
    const field = {
      name: key,
      type: value.type,
      values: value.values ? value.values : [],
      valid: true, // Assuming all fields from JSON are valid initially
      message: '' // No error message initially
    };
    fieldsArray.push(field);
  }
  return fieldsArray;
}

function validateFields() {
  const fieldNames = new Set();
  let allFieldsValid = true;
  for (const field of fields.value) {
    field.valid = true; // Assume field is valid initially
    field.message = ''; // Reset message

    // Check if field name is blank
    if (!field.name.trim()) {
      field.valid = false;
      field.message = 'Field name cannot be blank';
      allFieldsValid = false;
      continue; // Move to the next field
    }
    // Check for duplicate field names
    if (fieldNames.has(field.name)) {
      field.valid = false;
      field.message = `Duplicate field name found: ${field.name}`;
      allFieldsValid = false;
      continue; // Move to the next field
    } else {
      fieldNames.add(field.name);
    }
    // Check if non-text fields have an empty values array
    if (field.type !== 'text' && field.type !== 'date') {
      if (!field.values || field.values.length === 0) {
        field.valid = false;
        field.message = `Field "${field.name}" of type "${field.type}" must have at least one value`;
        allFieldsValid = false;
        continue; // Move to the next field
      }
      const valueSet = new Set();
      for (const value of field.values) {
        if (value === '' || value === undefined || value === null) {
          field.valid = false;
          field.message = `Invalid value in field "${field.name}". Values cannot be empty.`;
          allFieldsValid = false;
          break; // Stop checking values and move to the next field
        }
        if (valueSet.has(value)) {
          field.valid = false;
          field.message = `Duplicate value "${value}" found in field "${field.name}"`;
          allFieldsValid = false;
          break; // Stop checking values and move to the next field
        } else {
          valueSet.add(value);
        }
      }
    }
  }
  return allFieldsValid;
}

const iframeLink = ref('');
const loading = ref(true);
const saving = ref(false);
const saveSuccess = ref(false);
const saveError = ref('');

const params = ref('');

async function saveNewConfig() {
  try {
    const customFields = generateFields();
    if (!customFields) {
      return;
    }
    const url = createParamsUrl(customFields);
    console.log('Saving new config...');
    saving.value = true;
    const response = await axios.post(
      '/api/virtualterminalurl',
      { url, customFields },
      {
        headers: {
          Authorization: `Bearer ${apikey}`,
          'Content-Type': 'application/json'
        } // Use the apikey from the URL query
      }
    );
    await Promise.resolve(getClientDetails());
    saveSuccess.value = true;
  } catch (error) {
    console.error('Error saving new config:', error);
    saveError.value = 'Failed to save new configuration.';
  } finally {
    saving.value = false;
  }
}

async function copyJsonToClipboard() {
  const json = generateFields();
  try {
    await navigator.clipboard.writeText(JSON.stringify(json, null, 2));
    console.log('JSON copied to clipboard');
  } catch (err) {
    console.error('Failed to copy JSON to clipboard', err);
  }
}

function handlePaste(event) {
  const paste = (event.clipboardData || window.clipboardData).getData('text');
  try {
    const result = JSON.parse(paste);
    if (Array.isArray(result)) {
      result.forEach((field) => {
        field.valid = true;
        field.message = '';
        field.valuesOpen = false;
        field.newValue = '';
        field.required = !!field.required;
      });
      fields.value = result;
      console.log('Pasted content has been set to fields');
      validateFields();
    } else {
      console.error('Pasted content is not a valid array');
    }
  } catch (e) {
    console.error('Failed to parse pasted content as JSON', e);
  }
}

onMounted(async () => {
  try {
    if (!apikey) {
      throw new Error('API key is required');
    }
    getClientDetails();
    const response = await axios.get('/api/virtualterminalurl', {
      headers: { Authorization: `Bearer ${apikey}` } // Use the apikey from the URL query
    });

    try {
    iframeLink.value = response.data?.message?.url;
    const urlParams = new URLSearchParams(new URL(iframeLink.value).search);
    const paramsString = urlParams.get('params');
    if (paramsString) {
      const decodedParams = atob(paramsString);
      try {
        const paramsJson = JSON.parse(decodedParams);
        if (
          paramsJson?.customFields &&
          Array.isArray(paramsJson.customFields)
        ) {
          fields.value = paramsJson.customFields;
          if (validateFields()) {
            //   // theoretically could use to migrate old custom fields over.
            //   // prob best to not do this though
            //   if (
            //     !response.data?.message?.customFields &&
            //     paramsJson.customFields
            //   ) {
            //     try {
            //       await axios.post(
            //         '/api/virtualterminalurl',
            //         {
            //           customFields: paramsJson.customFields
            //         },
            //         {
            //           headers: { Authorization: `Bearer ${apikey}` }
            //         }
            //       );
            //       console.log('Posted customFields to virtualterminalurl');
            //     } catch (postError) {
            //       console.error(
            //         'Failed to post customFields to virtualterminalurl:',
            //         postError
            //       );
            //     }
            //   }
          }
        }
        if (paramsJson?.paymentOptions && paramsJson.paymentOptions.length > 0) {
          paymentOptions.value = paramsJson.paymentOptions;
        }
        if (Object.keys(paramsJson).includes('amount')) {
          amount.value = paramsJson.amount;
        } else {
          amount.value = 0;
        }
        if (paramsJson?.showBillingDetails) {
          showBillingDetails.value = paramsJson.showBillingDetails;
        }
        if (Object.keys(paramsJson).includes('hasSubscriptionAccess')) {
          hasSubscriptionAccess.value = paramsJson.hasSubscriptionAccess;
        }
        if (Object.keys(paramsJson).includes('freeFormAmount')) {
          freeFormAmount.value = paramsJson.freeFormAmount;
        }
        if (paramsJson?.buttonTopPosition) {
          buttonTopPosition.value = paramsJson.buttonTopPosition;
        }
        if (Object.keys(paramsJson).includes('showLocationsDropdown')) {
          showLocationsDropdown.value = paramsJson.showLocationsDropdown;
        }
        if (Object.keys(paramsJson).includes('showLogoUrl')) {
          showLogoUrl.value = paramsJson.showLogoUrl;
        }
        console.log('Decoded and parsed params:', paramsJson);
      } catch (error) {
        console.error('Failed to parse params JSON:', error);
      }
    }
    } catch (e) {
      console.error('could not parse paramsString in ConfigureTerminal', e);
    }

    if (response.data?.message?.customFields) {
      fields.value = response.data.message.customFields;
      validateFields();
    }
  } catch (error) {
    console.error('Error fetching virtual terminal link:', error);
    params.value = btoa(JSON.stringify({ apikey, isVt: true })); // Use the apikey from the URL query
    iframeLink.value = `${window.location.origin}/responsive-iframe/payment?params=${params.value}`;
  } finally {
    loading.value = false;
  }
});
</script>
<template>
  <div class="top-panel d-flex align-items-center">
    <button class="btn" @click="$router.go(-1)">
      <svg
        viewBox="0 0 16 16"
        class="bi bi-arrow-left"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M15 8a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708.708L2.707 7.5H14.5A.5.5 0 0 1 15 8z"
        />
      </svg>
      Back
    </button>
    <div class="mb-3 ms-auto me-auto">
      <!-- <span class="dashboard-title">Virtual Terminal</span> -->
    </div>
    <button
      class="btn btn-primary"
      @click="() => router.push(`/virtual-terminal/${apikey}`)"
    >
      Go To Virtual Terminal
    </button>
  </div>
  <div class="container mt-5 management-page" style="max-width: 800px">
    <h1 class="mb-4">Virtual Terminal Configurator</h1>
    <h3 class="mb-2">{{ clientLinkConfig?.merchant?.name }}</h3>

    <div v-if="loading">
      <BaseSpinner />
    </div>
    <div v-else-if="!loading && !saveSuccess">
      <div class="mt-3">
        <p class="fz-20 color-dark fw-700">Payment Methods</p>
        <div class="options-container">
          <base-spinner v-if="loadingAvailablePaymentOptions" />

          <p
              v-if="!filteredPaymentOptions.length && !loadingAvailablePaymentOptions"
              class="text-center fz-14 color-grey-medium pt-2"
          >
            No payment methods available
          </p>

          <div class="d-flex flex-column mt-2">
            <div>
              <base-checkbox
                  v-for="paymentOption in filteredPaymentOptions"
                  :key="paymentOption.value"
                  v-model="paymentOptions"
                  class="w-100 checkbox"
                  :disabled="paymentOption.disabled && paymentOption.disabled({ paymentType })"
                  :label="paymentOption.name"
                  :value="paymentOption.value"
              >
                <template #label>
                  <div class="d-flex align-items-baseline">
                    <p class="form-check-label fz-14 color-dark">{{ paymentOption.name }}</p>
                    <base-tooltip
                        v-if="paymentOption.tooltip"
                        :title="paymentOption.tooltip"
                        class="color-dark cursor-pointer question ml-2"
                        placement="right"
                        :multiple-lines="false"
                    >
                      <help-circle-outline :size="16" />
                    </base-tooltip>
                  </div>
                </template>
              </base-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <!--
        <button class="btn btn-secondary" :disabled="!iframeLink" @click="copyIframeLinkToClipboard">
          Copy iFrame Link to Clipboard
        </button>
        -->
        <button class="btn btn-secondary" :disabled="!iframeLink" @click="copyShortIframeLinkToClipboard">
          Copy shortened Payment Page Link to Clipboard
        </button>
      </div>
      <div class="mt-3">
        <p>
          Button links for Payment Page:
        </p>
        <a :href="'https://tripleplaypay.com/url/' + clientLinkConfig?.url_id">
          <img src="https://tripleplaypay.com/static/pay-button.png" alt="pay button" style="max-height: 50px;">
        </a>
        <hr />
        <a :href="'https://tripleplaypay.com/url/' + clientLinkConfig?.url_id">
          <img src="https://tripleplaypay.com/static/pay-button-small.png" alt="pay button" style="max-height: 35px;">
        </a>
      </div>
      <div class="mt-3">
        <p class="fz-20 color-dark fw-700">Input Fields</p>
        <ask-option v-model="showBillingDetails" label="Billing Information" class="mt-3" />
        <ask-option v-model="freeFormAmount" label="Free Form Amount" class="mt-3" />
        <div v-if="!freeFormAmount" class="mt-3">
          <label for="fixedAmount" class="form-label">Amount</label>
          <input
              v-model="amount"
              type="number"
              id="fixedAmount"
              class="form-control"
              placeholder="Enter the Amount"
          />
        </div>
        <ask-option v-model="buttonTopPosition" label="Button Is In Top Position" class="mt-3" />
        <ask-option v-model="showLocationsDropdown" label="Show Locations Dropdown" class="mt-3" />
        <ask-option v-model="showLogoUrl" label="Show Logo" class="mt-3" />
        <ask-option v-model="hasSubscriptionAccess" label="Show Is Recurring" class="mt-3" />
      </div>

      <div class="mt-3">
        <p class="fz-20 color-dark fw-700">Custom Fields</p>
      </div>
      <div v-for="(field, index) in fields" :key="index" class="mb-3 mt-3">
        <div :class="{ 'input-group mb-2': true, 'border-danger': !field.valid }">
          <input
              v-model="field.name"
              type="text"
              :class="{ 'form-control': true, 'is-invalid': !field.valid }"
              placeholder="Field Name"
              @blur="validateFields"
          />
          <select
              v-model="field.type"
              :class="{ 'form-select': true, 'is-invalid': !field.valid }"
          >
            <option value="text">Textbox</option>
            <option value="checkbox">Checkbox List</option>
            <option value="radio">Radio Button List</option>
            <option value="dropdown">Dropdown List</option>
            <option value="date">Date</option>
          </select>
          <button class="btn btn-outline-danger" @click="removeField(index)">
            Remove
          </button>
          <button class="btn btn-outline-secondary" @click="moveFieldUp(index)">
            Up
          </button>
          <button class="btn btn-outline-secondary" @click="moveFieldDown(index)">
            Down
          </button>
        </div>
        <div v-if="!field.valid" class="text-danger">{{ field.message }}</div>

        <div
            v-if="['checkbox', 'radio', 'dropdown'].includes(field.type)"
            class="ms-4 ps-4 border-start border-3 border-secondary"
        >
          <div class="form-label d-flex justify-content-between align-items-center">
            <div>{{ field.name }} Options:</div>
            <button class="btn btn-info" @click="field.valuesOpen = !field.valuesOpen">
              <span v-if="field.valuesOpen"> Hide </span>
              <span v-else> Show ({{ field?.values?.length ?? 0 }})</span>
            </button>
          </div>
          <div v-if="field.valuesOpen">
            <div
                v-for="(fieldValue, valueIndex) in field.values"
                :key="valueIndex"
                class="value-input-row mb-1"
            >
              <div class="input-group">
                <input type="text" class="form-control" :value="fieldValue" readonly />
                <button
                    class="btn btn-outline-danger"
                    @click="removeValueFromField(field.name, valueIndex)"
                >
                  Remove
                </button>
              </div>
            </div>
            <div class="input-group mb-3">
              <input
                  v-model="field.newValue"
                  type="text"
                  class="form-control"
                  placeholder="Option Value"
                  @blur="addValueToField(field.name, field.newValue); field.newValue = ''"
              />
              <button
                  class="btn btn-outline-secondary"
                  @click="addValueToField(field.name, field.newValue); field.newValue = ''"
              >
                Add Option
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="my-3">
        <button class="btn btn-primary me-2" @click="addField">
          Add Field
        </button>

        <button
          class="btn btn-success"
          :disabled="loadingAvailablePaymentOptions"
          @click="saveNewConfig"
        >
          Save Configuration
        </button>
      </div>
    </div>
    <div v-else-if="saveSuccess">
      <div class="alert alert-success" role="alert">
        Configuration saved successfully!
      </div>
      <div class="d-flex justify-content-between">
        <button class="btn btn-secondary" @click="$router.go()">
          Change Configuration
        </button>
        <button class="btn btn-secondary" :disabled="!iframeLink" @click="copyIframeLinkToClipboard">
          Copy iFrame Link to Clipboard
        </button>
        <button class="btn btn-secondary" :disabled="!iframeLink" @click="copyShortIframeLinkToClipboard">
          Copy shortened iFrame Link to Clipboard
        </button>
        <!-- <a :href="iframeLink" target="_blank" class="btn btn-success">
          Open Virtual Terminal
        </a> -->
        <button
          class="btn btn-primary"
          @click="() => router.push(`/virtual-terminal/${apikey}`)"
        >
          Use New Virtual Terminal
        </button>
      </div>
    </div>
    <div v-if="isLocalhostOrTest">
      <div class="d-flex mb-2">
        <button class="btn btn-success me-2" @click="generateFields">
          Generate JSON
        </button>
        <button class="btn btn-secondary" @click="copyJsonToClipboard">
          Copy JSON
        </button>
      </div>
      <textarea
        v-model="jsonOutput"
        readonly
        class="form-control"
        style="height: 150px"
        @paste.prevent="handlePaste"
      ></textarea>
    </div>
  </div>
</template>

<style lang="scss">
.top-panel {
  display: flex;
  /* justify-content: space-between;
  align-items: center; */
}
.dashboard-title {
  font-size: 24px;
  color: #383838;
  /* margin-bottom: 10px; */
}
.field-config {
  margin-bottom: 10px;
}
</style>
