export const PRIMARY_CLIENT_ID = '4a731b24-0beb-457c-9585-2362c6a53cf5';

export const FIREBASE_ERROR_CODES = {
  'auth/missing-email': 'Email is required',
  'auth/invalid-user-token': 'Session has expired',
  'auth/user-token-expired': 'Session has expired',
  'auth/email-already-in-use': 'Such user already exists',
  'auth/credential-already-in-use': 'Such user already exists',
  'auth/account-exists-with-different-credential':
    'Such user is registered with different network',
  'auth/invalid-email': 'Email is not valid',
  'auth/weak-password': 'Password is too weak',
  'auth/user-not-found': 'Credentials are not valid',
  'auth/wrong-password': 'Credentials are not valid'
};

export const PERMISSION_PRIMITIVES = {
  DOCUMENTS: 'Documents',
  MERCHANT_ACCOUNTS: 'Merchant Accounts',
  OMIT_CC_ZIP: 'Omit CC Zip',
  PAYMENT_PAGES: 'Payment Pages',
  PAYOUTS: 'Payouts',
  PERMISSIONS: 'Permissions',
  RECURRING_CHARGES: 'Recurring Charges',
  REPORTS: 'Reports',
  REPORTS_ADVANCED: 'Reports - Advanced Fields',
  TERMINAL_ADMIN: 'Terminal Admin',
  TRANSACTION_APPLE_PAY: 'Transaction Apple Pay',
  TRANSACTION_AUTH: 'Transaction Auth',
  TRANSACTION_CAPTURE: 'Transaction Capture',
  TRANSACTION_CASH: 'Transaction Cash',
  TRANSACTION_CHARGE: 'Transaction Charge',
  TRANSACTION_CREDIT_ACH_REFUND: 'Transaction Credit (ACH Refund)',
  TRANSACTION_DEBIT_ACH_CHARGE: 'Transaction Debit (ACH Charge)',
  TRANSACTION_GOOGLE_PAY: 'Transaction Google Pay',
  TRANSACTION_REFUND: 'Transaction Refund',
  TRANSACTION_SWIPE: 'Transaction Swipe',
  TRANSACTION_TERMINALS: 'Transaction Terminals',
  TRANSACTION_VOID: 'Transaction Void',
  USERS: 'Users',
  VAULT: 'Vault',
  VIRTUAL_TERMINAL: 'Virtual Terminal',
  VIRTUAL_TERMINAL_CONFIGURATION: 'Virtual Terminal Configuration',
};

export const API_KEY_LS_NAME = 'create-link-api-key';
export const VIEW_AS_KEY = 'view-as';

export const FIREBASE_LINK_MODES = {
  SIGN_IN: 'signIn',
  VERIFY: 'verifyEmail'
};

export const OWNERSHIP_TYPES = [
  {
    value: '',
    title: 'Ownership type'
  },
  {
    value: 'Sole Proprietor',
    title: 'Sole Proprietor'
  },
  {
    value: 'C-Corp Private',
    title: 'C-Corp Private'
  },
  {
    value: 'C-Corp Public',
    title: 'C-Corp Public'
  },
  {
    value: 'S-Corp Private',
    title: 'S-Corp Private'
  },
  {
    value: 'S-Corp Public',
    title: 'S-Corp Public'
  },
  {
    value: 'LLC Private',
    title: 'LLC Private'
  },
  {
    value: 'LLC Public',
    title: 'LLC Public'
  },
  {
    value: 'Not For Profit',
    title: 'Not For Profit'
  },
  {
    value: 'Partnership Private',
    title: 'Partnership Private'
  },
  {
    value: 'Partnership',
    title: 'Partnership'
  },
  {
    value: 'Government Agency',
    title: 'Government Agency'
  }
];

export const ENROLL_STATUSES = {
  BOARDED: 'BOARDED',
  INCOMPLETE: 'SEND LINK',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING'
};

/**
 * @typedef {{
 *   title: string
 *   path: string
 *   icon: Object
 *   roles?: string[]
 *   permissions?: string[]
 *   tppOnly: boolean
 * }} MenuItem
 */

/**
 * @type {MenuItem[]}
 */
export const MANAGEMENT_MENU = [
  {
    title: 'Certification',
    path: '/certification-page',
    icon: 'bi bi-award-fill',
    permissions: [PERMISSION_PRIMITIVES.PERMISSIONS],
    roles: ['admin', 'user', 'parent', 'healnow'],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: true,
  },
  {
    title: 'Home',
    path: '/home',
    icon: 'bi bi-house-door-fill',
    roles: ['admin', 'user', 'parent', 'healnow'],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Create Enrollment',
    path: '/enroll-portal-page',
    icon: 'bi bi-person-lines-fill',
    permissions: [PERMISSION_PRIMITIVES.PERMISSIONS],
    roles: ['admin', 'user', 'parent', 'healnow'],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: true,
    isCertificationClient: false,
  },
  {
    title: 'Mimic',
    path: '/mimic',
    icon: 'bi bi-eye-fill',
    roles: ['admin', 'alpha', 'parent'],
    permissions: [PERMISSION_PRIMITIVES.PERMISSIONS],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Monthly Billing',
    path: '/monthly-billing',
    icon: 'bi bi-cash-coin',
    roles: ['admin'],
    tppOnly: true,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Onboarding',
    path: '/onboarding',
    icon: 'bi bi-airplane-fill',
    roles: ['admin', 'user'],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: true,
    isCertificationClient: false,
  },
  {
    title: 'Admin Accounts',
    path: '/accounts',
    icon: 'bi bi-aspect-ratio-fill',
    roles: ['admin', 'healnow'],
    tppOnly: true,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Accounts',
    path: '/merchants',
    icon: 'bi bi-stickies-fill',
    roles: ['admin', 'parent', 'user'],
    permissions: [PERMISSION_PRIMITIVES.MERCHANT_ACCOUNTS],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Reporting',
    path: '/reporting',
    icon: 'bi bi-file-bar-graph-fill',
    roles: ['admin', 'user', 'parent'],
    permissions: [PERMISSION_PRIMITIVES.REPORTS],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Admin Reporting',
    path: '/admin-reporting',
    icon: 'bi bi-file-binary-fill',
    roles: ['admin'],
    permissions: [PERMISSION_PRIMITIVES.REPORTS],
    tppOnly: true,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Costing',
    path: '/costing',
    icon: 'bi bi-bar-chart-steps',
    roles: ['admin'],
    permissions: [PERMISSION_PRIMITIVES.REPORTS],
    tppOnly: true,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Statements',
    path: '/payouts',
    icon: 'bi bi-postage-fill',
    roles: ['admin', 'parent', 'user'],
    permissions: [PERMISSION_PRIMITIVES.PAYOUTS],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Billing',
    path: '/billing',
    icon: 'bi bi-bell-fill',
    roles: ['admin', 'parent', 'user'],
    permissions: [PERMISSION_PRIMITIVES.REPORTS],
    tppOnly: true,
    tppAndPartnersOnly: true,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Recurring Charges',
    path: '/report/recurring',
    icon: 'bi bi-calendar-check-fill',
    roles: ['user', 'admin', 'parent'],
    permissions: [PERMISSION_PRIMITIVES.RECURRING_CHARGES],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Vault',
    path: '/vault',
    icon: 'bi bi-safe2-fill',
    roles: ['admin', 'user', 'parent'],
    permissions: [PERMISSION_PRIMITIVES.VAULT],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Permissions',
    path: '/permissions',
    icon: 'bi bi-shield-fill-check',
    roles: ['admin', 'alpha'],
    permissions: [PERMISSION_PRIMITIVES.PERMISSIONS],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Sublocations',
    path: '/sublocations',
    icon: 'bi bi-cart-check-fill',
    roles: ['admin', 'alpha'],
    permissions: [PERMISSION_PRIMITIVES.PERMISSIONS],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Users',
    path: '/users',
    icon: 'bi bi-person-square',
    roles: ['user', 'admin', 'parent', 'healnow'],
    permissions: [PERMISSION_PRIMITIVES.USERS],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Terminals',
    path: '/terminals',
    icon: 'bi bi-tablet-fill',
    roles: ['user', 'admin', 'parent'],
    permissions: [PERMISSION_PRIMITIVES.TERMINAL_ADMIN],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Risk Dashboard',
    path: '/risk',
    icon: 'bi bi-card-text',
    roles: ['admin'],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: false,
    isCertificationClient: false,
  },
  {
    title: 'Rev Share',
    path: '/rev-share',
    icon: 'bi bi-cash-stack',
    roles: ['admin', 'user'],
    tppOnly: false,
    tppAndPartnersOnly: false,
    parentsOnly: true,
    isCertificationClient: false,
  },
];

export const PLATFORM_NAMES = {
  triple: 'Triple Play Pay',
  'simon-payments': 'Simon Payments'
};

export const DEFAULT_PLATFORM_NAME = 'triple';
export const USER_DETAILS_KEY = 'user_details';
export const SIDEBAR_COLLAPSED = 'sidebar_collapsed';
export const REPLACE_PERCENT_VALUE = '-0-0-0-0-0-0';

export const PAYMENT_TYPES = {
  CHARGE: 'charge',
  SUBSCRIPTION: 'subscription'
};

export const PAYMENT_OPTIONS_INTERFACE = {
  CARD: 'credit_card',
  BANK: 'bank',
  CRYPTO: 'crypto',
  TERMINAL: 'terminal'
};

export const PAYMENT_OPTIONS_WITH_CRYPTO = [
  {
    name: 'Credit/Debit Card',
    value: 'credit_card'
  },
  {
    name: 'Cash',
    value: 'cash'
  },
  {
    name: 'Crypto',
    value: 'crypto'
  },
  {
    name: 'ACH',
    value: 'bank',
    disabled({ paymentType }) {
      return paymentType === PAYMENT_TYPES.SUBSCRIPTION;
    },
    tooltip: 'May take up to 5 days to transfer.'
  },
  {
    name: 'Swipe',
    value: 'swipe',
    disabled({ paymentType }) {
      return paymentType === PAYMENT_TYPES.SUBSCRIPTION;
    }
  },
  {
    name: 'Terminal',
    value: 'terminal',
    disabled({ paymentType }) {
      return paymentType === PAYMENT_TYPES.SUBSCRIPTION;
    }
  },
  {
    name: 'Apple Pay',
    value: 'apple_pay',
    disabled({ paymentType }) {
      return paymentType === PAYMENT_TYPES.SUBSCRIPTION;
    }
  },
  {
    name: 'Google Pay',
    value: 'google_pay',
    disabled({ paymentType }) {
      return paymentType === PAYMENT_TYPES.SUBSCRIPTION;
    }
  }
];

export const PAYMENT_OPTIONS = [
  {
    name: 'Credit/Debit Card',
    value: 'credit_card'
  },
  {
    name: 'Cash',
    value: 'cash'
  },
  // {
  //   name: 'Crypto',
  //   value: 'crypto'
  // },
  {
    name: 'ACH',
    value: 'bank',
    disabled({ paymentType }) {
      return paymentType === PAYMENT_TYPES.SUBSCRIPTION;
    },
    tooltip: 'May take up to 5 days to transfer.'
  },
  {
    name: 'Swipe',
    value: 'swipe',
    disabled({ paymentType }) {
      return paymentType === PAYMENT_TYPES.SUBSCRIPTION;
    }
  },
  {
    name: 'Terminal',
    value: 'terminal',
    disabled({ paymentType }) {
      return paymentType === PAYMENT_TYPES.SUBSCRIPTION;
    }
  },
  {
    name: 'Apple Pay',
    value: 'apple_pay',
    disabled({ paymentType }) {
      return paymentType === PAYMENT_TYPES.SUBSCRIPTION;
    }
  },
  {
    name: 'Google Pay',
    value: 'google_pay',
    disabled({ paymentType }) {
      return paymentType === PAYMENT_TYPES.SUBSCRIPTION;
    }
  }
];

export const INTERVAL_OPTIONS = [
  {
    value: 'daily',
    text: 'Days'
  },
  {
    value: 'weekly',
    text: 'Weeks'
  },
  {
    value: 'monthly',
    text: 'Months'
  },
  {
    value: 'yearly',
    text: 'Years'
  }
];

export const CRYPTO_TYPES = [
  {
    value: 'btc',
    text: 'BTC'
  },
  {
    value: 'eth',
    text: 'ETH'
  }
];

export const ACCOUNT_TYPE_OPTIONS = [
  {
    value: 'savings',
    text: 'Savings'
  },
  {
    value: 'checking',
    text: 'Checking'
  },
  {
    value: 'business',
    text: 'Business'
  }
];

export const CRYPTO_OPTIONS = [
  {
    value: 'curpay',
    text: 'Curpay'
  },
  {
    value: false,
    text: 'Disabled'
  }
];

export const PAYMENT_CONFIG_OPTIONS = [
  {
    value: false,
    text: 'Disabled'
  },
  {
    value: 'fis',
    text: 'FIS'
  },
  {
    value: 'xpress',
    text: 'Xpress'
  },
  {
    value: 'epx',
    text: 'EPX'
  },
  {
    value: 'bluesnap',
    text: 'Bluesnap'
  },
  {
    value: 'merchante',
    text: 'MerchantE'
  }
];

export const PAYOUT_OPTIONS = [
  {
    value: 'split_settlement',
    text: 'Split settlement'
  },
  {
    value: 'dynamic_payout_weekly',
    text: 'Dynamic Payout weekly'
  },
  {
    value: 'dynamic_payout_monthly',
    text: 'Dynamic Payout monthly'
  }
];

export const TERMINALS_OPTIONS = [
  {
    value: false,
    text: 'Disabled'
  },
  {
    value: 'fis',
    text: 'Ingenico'
  },
  {
    value: 'dejavooo',
    text: 'Dejavoo'
  },
  {
    value: 'tpp',
    text: 'TPP'
  }
];

export const INTERVAL_OPTIONS_KEYS = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
};

export const INTERVAL_NOUNS = {
  daily: 'days',
  weekly: 'weeks',
  monthly: 'months',
  yearly: 'years'
};

export const NOTE_CATEGORIES = [
  {
    value: 'risk',
    text: 'Risk'
  },
  {
    value: 'operations',
    text: 'Operations'
  },
  {
    value: 'note',
    text: 'Note'
  },
  {
    value: 'system',
    text: 'System'
  },
  {
    value: 'dnc',
    text: 'DNC'
  },
  {
    value: 'statementNote',
    text: 'Statement Note'
  }
];

export const NOTE_OPTIONS = [
  {
    risk: 'Risk',
    operations: 'Operations',
    note: 'Note',
    system: 'System'
  }
];

export const SIMPLE_BOOL_OPTIONS = [
  {
    value: false,
    text: 'Disabled'
  },
  {
    value: true,
    text: 'Enabled'
  }
];

export const ZIP_CODE_OPTIONS = [
  {
    value: 'required',
    text: 'Required'
  },
  {
    value: 'disabled',
    text: 'Disabled'
  }
];

export const MONTHS_OPTIONS = [
  {
    value: 1,
    text: 'January'
  },
  {
    value: 2,
    text: 'February'
  },
  {
    value: 3,
    text: 'March'
  },
  {
    value: 4,
    text: 'April'
  },
  {
    value: 5,
    text: 'May'
  },
  {
    value: 6,
    text: 'June'
  },
  {
    value: 7,
    text: 'July'
  },
  {
    value: 8,
    text: 'August'
  },
  {
    value: 9,
    text: 'September'
  },
  {
    value: 10,
    text: 'October'
  },
  {
    value: 11,
    text: 'November'
  },
  {
    value: 12,
    text: 'December'
  }
];

export const SOURCE = 'link';
export const SAVED_CRED_KEY = 'saved_credential';
export const BEARER_TOKEN_KEY = 'bearer_token';

export const CLIENT_PAYMENT_OPTIONS = [
  {
    value: false,
    text: 'Disabled'
  },
  {
    value: 'fis',
    text: 'FIS'
  },
  {
    value: 'xpress',
    text: 'Xpress'
  },
  {
    value: 'epx',
    text: 'EPX'
  },
  {
    value: 'bluesnap',
    text: 'Bluesnap'
  },
  {
    value: 'merchante',
    text: 'MerchantE'
  }
];

export const CLIENT_STATUSES = [
  {
    text: 'New',
    value: null
  },
  {
    text: 'CTA Sent',
    value: 'cta_sent'
  },
  {
    text: 'CTA Signed',
    value: 'cta_signed'
  },
  {
    text: 'In Development',
    value: 'in_development'
  },
  {
    text: 'Onboarding',
    value: 'onboarding'
  },
  {
    text: 'Active',
    value: 'Active'
  },
  {
    text: 'Inactive',
    value: 'Inactive'
  }
];

export const VIRTUAL_TERMINAL_DEFAULTS = {
  isVt: true,
  freeFormAmount: true,
  paymentType: 'charge',
  description: '""',
  newWindow: false,
  fromLink: true,
  start: '2024-02-03',
  frequency: 1,
  interval: 'monthly',
  paymentOptions: ['credit_card', 'bank', 'swipe'],
  savePaymentToken: false,
  terminal: null,
  showEmail: true,
  trial: null,
  zipMode: 'required',
  source: 'link',
  fullName: true,
  billingAddress: true,
  optIn: false,
  emailOption: 'optional',
  phoneOption: false,
  showDescription: false,
  styles: {
    input: {
      borderLeftStyle: 'hidden',
      borderTopStyle: 'hidden',
      borderRightStyle: 'hidden',
      borderRadius: '0',
      background: 'transparent',
      borderColor: '#d2d2d2',
      borderStyle: 'solid',
      borderWidth: '1px'
    }
  },
  allowTip: false
};

export const APPEARANCE_OPTIONS = {
  default: 'default',
  custom: 'custom'
};

export const PAYMENT_TYPE_ACH = 'debit';
export const PAYMENT_TYPE_ACH_REFUND = 'credit';
export const PAYMENT_TYPE_CARD = 'cc';
export const PAYMENT_TYPE_ACH_READABLE = 'ach_charge';
export const PAYMENT_TYPE_CARD_READABLE = 'card_charge';
export const PAYMENT_TYPE_ACH_REFUND_READABLE = 'ach_refund';
export const PAYMENT_METHOD_CARD = 'card';
export const PAYMENT_METHOD_CHECK = 'check';

export const VUE_APP_MODE = process.env.VUE_APP_MODE;
export const VUE_APP_COMMIT_HASH = process.env.VUE_APP_COMMIT_HASH;
